// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-templates-about-tpl-js": () => import("./../../../src/templates/AboutTpl.js" /* webpackChunkName: "component---src-templates-about-tpl-js" */),
  "component---src-templates-docs-tpl-js": () => import("./../../../src/templates/DocsTpl.js" /* webpackChunkName: "component---src-templates-docs-tpl-js" */),
  "component---src-templates-error-tpl-js": () => import("./../../../src/templates/ErrorTpl.js" /* webpackChunkName: "component---src-templates-error-tpl-js" */),
  "component---src-templates-help-tpl-js": () => import("./../../../src/templates/HelpTpl.js" /* webpackChunkName: "component---src-templates-help-tpl-js" */),
  "component---src-templates-home-tpl-js": () => import("./../../../src/templates/HomeTpl.js" /* webpackChunkName: "component---src-templates-home-tpl-js" */),
  "component---src-templates-partners-tpl-js": () => import("./../../../src/templates/PartnersTpl.js" /* webpackChunkName: "component---src-templates-partners-tpl-js" */),
  "component---src-templates-press-tpl-js": () => import("./../../../src/templates/PressTpl.js" /* webpackChunkName: "component---src-templates-press-tpl-js" */)
}

